import React from "react";
import { Heading7, ImageLite, Paragraph } from "shared/components";

import styles from "./Item.module.scss";

export const Item = ({ item }) => {
  const { title, subTitle, location, media } = item;

  return (
    <div className={styles.item}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <Heading7 className={styles.title}>{title}</Heading7>
          <Paragraph className={styles.subtitle}>{subTitle}</Paragraph>
        </div>
        <Paragraph className={styles.location}>{location}</Paragraph>
        <ImageLite image={media} className={styles.background} />
      </div>
    </div>
  );
};
