import classNames from "classnames";
import { Container, Heading2, Paragraph, Tabs } from "shared/components";
import { TabsProvider } from "shared/components/Tabs/Tabs.Provider";

import styles from "./Content008.module.scss";
import { Partners } from "./Partners";

export const Content008 = ({ payload }) => {
  const title = payload?.title ?? "";
  const description = payload?.description ?? "";
  const partners = payload?.partners ?? [];
  const appearance = payload?.template ?? "light";

  const extractedTabs = [...new Set(partners.map((item) => item.continent))];

  return (
    <div className={classNames(styles.wrapper, styles[appearance])}>
      <Container className={styles.inner}>
        <div className={styles.header}>
          <Heading2 className={styles.title}>{title}</Heading2>
          <Paragraph className={styles.description} color="grey">
            {description}
          </Paragraph>
        </div>
        <TabsProvider items={extractedTabs} defaultTab tabsEnabled={true}>
          <Tabs appearance="justify" />
          <Partners items={partners} />
        </TabsProvider>
      </Container>
    </div>
  );
};

export default Content008;
