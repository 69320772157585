import { useMemo } from "react";
import { useTabsState } from "shared/components/Tabs/Tabs.hooks";

import styles from "../Content008.module.scss";
import { Item } from "../Item";

export const Partners = ({ items }) => {
  const { activeTab, isDefaultTab } = useTabsState();

  const filterPartners = useMemo(() => {
    if (isDefaultTab) {
      return items;
    }

    return items.filter(
      ({ continent }) => continent.toLowerCase() === activeTab.toLowerCase()
    );
  }, [isDefaultTab, activeTab, items]);

  return (
    <div className={styles.content}>
      <div className={styles.items}>
        {filterPartners.map((item) => (
          <Item item={item} key={item.id} />
        ))}
      </div>
    </div>
  );
};
